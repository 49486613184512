import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Link } from "components/Link";

type LogoProps = {
  src: string;
  title: string;
};

export const Logo: FC<LogoProps> = ({ src, title }) => {
  return (
    <Link url="/" className={styles.logo} aria-label="Navigate to home page">
      <img src={src} alt={title} />
    </Link>
  );
};
