import React, { FC, useCallback, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useLocationPage, useSearchedEvent } from "hooks";
import { useSearch } from "hooks/useSearch";
import { CmsBlogPost } from "../Blog";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Colour } from "../constants";
import styles from "./styles.module.scss";
import cx from "classnames";
import { Link } from "../Link";
import { blogPostUrl } from "../Blog/hooks";
import { Button } from "../Button";

const OPTIONS = {
  minLength: 2,
  keys: ["title", "subTitle", "keywords"]
};

export const SearchBox: FC = () => {
  const data = useStaticQuery(graphql`
    query AllPosts {
      allContentfulBlogPost(
        filter: {
          slug: {
            nin: [
              "test-article"
              "test-video-blog"
              "test-article-do-not-remove"
              "privacy-notice-pps"
              "privacy-notice-genie"
            ]
          }
        }
        limit: 500
      ) {
        nodes {
          title
          subTitle
          slug
          keywords
          categories {
            title
          }
        }
      }
    }
  `);

  const [searchTerm, setSearchTerm] = useState("");
  const { find, results, clearResults } = useSearch(
    data.allContentfulBlogPost.nodes,
    OPTIONS
  );
  const page = useLocationPage();
  const pushSearchedEvent = useSearchedEvent(page);
  const onSearchChanged = useCallback(
    (term: string) => {
      if (term.length >= OPTIONS.minLength) {
        find(term);
      } else {
        clearResults();
      }
      setSearchTerm(term);
    },
    [find, setSearchTerm]
  );

  useEffect(() => {
    if (results.status !== "skipped") {
      const resultsCount =
        results.status === "found" ? results.items.length : 0;
      pushSearchedEvent(results.searchTerm, resultsCount);
    }
  }, [results, pushSearchedEvent]);

  const showOverlay = () => searchTerm.length > OPTIONS.minLength - 1;
  const handleBlur = () => {
    clearResults();
    setSearchTerm("");
  };
  const getCurrentUrl = () => `/blog/search?q=${searchTerm}`;

  return (
    <Panel
      layout={PanelLayout.full}
      colour={Colour.warmStone}
      className={styles.searchBox}
      short
    >
      <div
        className={cx(styles.overlay, { [styles.shown]: showOverlay() })}
        onClick={handleBlur}
      />
      <PanelItem className={styles.searchBoxItem}>
        <div className={styles.relative}>
          <div className={styles.searchContainer}>
            <div className={styles.inputContainer}>
              <div className={styles.inputInner}>
                <input
                  aria-label="Search articles"
                  placeholder="Search articles"
                  value={searchTerm}
                  className={styles.input}
                  type="search"
                  onChange={e => onSearchChanged(e.target.value)}
                />
                <Button
                  className={styles.button}
                  url={getCurrentUrl()}
                  tabIndex={searchTerm.length <= OPTIONS.minLength ? -1 : 0}
                  disabled={searchTerm.length <= OPTIONS.minLength}
                >
                  Search
                </Button>
              </div>
              {results?.status === "not-found" && (
                <div className={styles.results}>
                  <h5 className={styles.noResults}>
                    We couldn’t find any results for "{results.searchTerm}". Why
                    not take a look at some of our latest articles?
                  </h5>
                </div>
              )}
              {results?.items.length ? (
                <div className={styles.results}>
                  {results?.items.map((post: CmsBlogPost) => {
                    return (
                      <Link
                        className={styles.searchBoxResult}
                        key={post.slug}
                        url={blogPostUrl(post)}
                      >
                        <p className={styles.item}>{post.title}</p>
                      </Link>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </PanelItem>
    </Panel>
  );
};
