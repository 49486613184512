import { asPortal, useDisableScroll } from "@mettle/hooks";
import React, { EventHandler, FC, MouseEvent, UIEvent } from "react";
import cx from "classnames";
import { useModalAnalytics, useFocusTrap } from "hooks";
import closeIcon from "assets/icons/close.svg";
import styles from "./styles.module.scss";

export interface ModalProps {
  id: string;

  onClose(): void;

  className: string;
  children?: React.ReactNode;
}

const stopPropagation: EventHandler<MouseEvent | UIEvent> = event =>
  event.stopPropagation();

export const ModalCloseButton: FC<{ onClose(): void }> = ({ onClose }) => {
  const handleKeydown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      onClose();
    }
  };

  return (
    <button
      data-testid="close-button"
      className={styles.closeButton}
      onMouseDown={onClose}
      onKeyDown={handleKeydown}
    >
      <img src={closeIcon} alt="Close" />
    </button>
  );
};

export const Modal: FC<ModalProps> = ({ id, children, onClose, className }) => {
  useDisableScroll();
  useModalAnalytics(id);

  return asPortal(
    <div
      data-testid="modal"
      className={styles.backdrop}
      onClick={onClose}
      onScroll={stopPropagation}
      tabIndex={-1}
    >
      <div
        ref={useFocusTrap(onClose)}
        className={cx(styles.modal, className)}
        onClick={stopPropagation}
        tabIndex={0}
      >
        <ModalCloseButton onClose={onClose} />
        {children}
      </div>
    </div>
  );
};
