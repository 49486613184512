import React, { FC } from "react";
import { CmsPageSectionType } from "./types";
import {
  TrustPilotSection,
  CmsTrustPilotSection
} from "components/HomePagePanel/TrustPilot";
import { CardPanel, CmsCardPanelSection } from "components/CardPanel";
import { CmsPageHeroSection, PageHero } from "components/PageHero";
import { CmsPageCarouselSection, PageCarousel } from "components/PageCarousel";
import { BlogList, CmsBlogListSection } from "components/Blog/BlogList";
import { Category, CmsCategorySection } from "components/Blog/Category";
import {
  CmsLatestPostsCarouselSection,
  LatestPostsCarousel
} from "components/LatestPostsCarousel";
import { DefaultLayout } from "../../layouts";
import {
  CmsContentHubNavBarSection,
  ContentHubNav
} from "components/ContentHubNav";
import {
  CmsFourCardPanelSection,
  FourCardPanel
} from "components/FourCardPanel";
import {
  CmsDismissibleBannerSection,
  DismissibleBanner
} from "components/DismissibleBanner";
import {
  CmsPaddedPageBannerSection,
  PaddedPageBanner
} from "components/PaddedPageBanner";
import {
  CenteredImagePanel,
  CmsCenteredImagePanelSection
} from "components/CenteredImagePanel";
import {
  CmsDisclaimerSection,
  DisclaimerSection
} from "components/DisclaimerSection";
import {
  CmsEmbeddedVideoSection,
  EmbeddedVideoSection
} from "components/EmbeddedVideoSection";
import {
  CmsInfoAccordionSection,
  InfoAccordion
} from "components/InfoAccordion";
import {
  CmsComparisonTableSection,
  ComparisonTableSection
} from "../ComparisonTableSection";
import { CmsQuoteCard, QuoteCard } from "../QuoteCard";
import { DynamicPageSections } from "./DynamicPageSections";
import { CalculatorSection, CmsCalculatorSection } from "../CalculatorSection";
import { CmsTickerTapeSection, TickerTapeSection } from "../TickerTapeSection";
import {
  CmsDismissiblePopover,
  DismissiblePopover
} from "../DismissiblePopover";

export interface CmsJsonSchema {
  jsonSchema: string;
}

export interface CmsPage {
  title: string;
  metaTitle?: string;
  metaDescription?: string;
  noIndex?: boolean;
  sections: CmsPageSection[];
  slug: string;
  children?: React.ReactNode;
  aboveFoldExperimentation?: boolean;
  jsonSchema?: CmsJsonSchema;
}

export type CmsPageSection =
  | CmsTrustPilotSection
  | CmsCardPanelSection
  | CmsPageHeroSection
  | CmsPageCarouselSection
  | CmsBlogListSection
  | CmsCategorySection
  | CmsLatestPostsCarouselSection
  | CmsContentHubNavBarSection
  | CmsFourCardPanelSection
  | CmsDismissibleBannerSection
  | CmsPaddedPageBannerSection
  | CmsCenteredImagePanelSection
  | CmsDisclaimerSection
  | CmsEmbeddedVideoSection
  | CmsInfoAccordionSection
  | CmsComparisonTableSection
  | CmsQuoteCard
  | CmsCalculatorSection
  | CmsTickerTapeSection
  | CmsDismissiblePopover;

export const PageSections: Record<
  CmsPageSectionType,
  React.FC<CmsPageSection>
> = {
  [CmsPageSectionType.TrustPilot]: TrustPilotSection,
  [CmsPageSectionType.CardPanel]: CardPanel,
  [CmsPageSectionType.PageHero]: PageHero,
  [CmsPageSectionType.PageCarousel]: PageCarousel,
  [CmsPageSectionType.BlogList]: BlogList,
  [CmsPageSectionType.Category]: Category,
  [CmsPageSectionType.LatestPosts]: LatestPostsCarousel,
  [CmsPageSectionType.ContentHubNavBar]: ContentHubNav,
  [CmsPageSectionType.FourCardPanel]: FourCardPanel,
  [CmsPageSectionType.DismissibleBanner]: DismissibleBanner,
  [CmsPageSectionType.PaddedPageBanner]: PaddedPageBanner,
  [CmsPageSectionType.CenteredImagePanel]: CenteredImagePanel,
  [CmsPageSectionType.DisclaimerSection]: DisclaimerSection,
  [CmsPageSectionType.EmbeddedVideoSection]: EmbeddedVideoSection,
  [CmsPageSectionType.InfoAccordion]: InfoAccordion,
  [CmsPageSectionType.ComparisonTableSection]: ComparisonTableSection,
  [CmsPageSectionType.QuoteCard]: QuoteCard,
  [CmsPageSectionType.CalculatorSection]: CalculatorSection,
  [CmsPageSectionType.TickerTapeSection]: TickerTapeSection,
  [CmsPageSectionType.DismissiblePopover]: DismissiblePopover
};

export const ComposedPage: FC<CmsPage> = ({
  metaTitle,
  title,
  metaDescription,
  noIndex,
  slug,
  sections,
  children,
  aboveFoldExperimentation,
  jsonSchema
}) => {
  return (
    <DefaultLayout
      title={metaTitle || title}
      description={metaDescription}
      noIndex={noIndex}
      aboveFoldExperimentation={aboveFoldExperimentation}
      jsonSchema={jsonSchema}
    >
      <DynamicPageSections sections={sections} slug={slug} />
      {children}
    </DefaultLayout>
  );
};
