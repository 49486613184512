import React, { FC } from "react";
import { graphql } from "gatsby";
import { AuthorPage, CmsAuthor } from "../components";
import { DefaultLayout } from "../layouts";

interface AuthorPageProps {
  data: {
    author: CmsAuthor;
  };
}

const AuthorTemplate: FC<AuthorPageProps> = ({ data: { author } }) => {
  return (
    <DefaultLayout title={author.name} description={author.name}>
      <AuthorPage author={author} />
    </DefaultLayout>
  );
};

export default AuthorTemplate;
export const ContentfulAuthorPageFragment = graphql`
  fragment ContentfulAuthorPageFragment on ContentfulAuthor {
    ...ContentfulAuthorWithPostsFragment
    sections {
      __typename
      ... on Node {
        ...ContentfulCardPanelFragment
        ...ContentfulPageHeroFragment
        ...ContentfulLatestPostsCarouselFragment
        ...ContentfulPageCarouselFragment
        ...ContentfulTrustPilotSectionFragment
        ...ContentfulFourCardPanelFragment
        ...ContentfulDismissibleBannerFragment
        ...ContentfulPaddedPageBannerFragment
        ...ContentfulCenteredImagePanelFragment
        ...ContentfulDisclaimerSectionFragment
        ...ContentfulEmbeddedVideoSectionFragment
        ...ContentfulInfoAccordionFragment
        ...ContentfulComparisonTableSectionFragment
        ...ContentfulQuoteCardFragment
        ...ContentfulBlogPostRichTextSectionFragment
        ...ContentfulTickerTapeSectionFragment
        ...ContentfulDismissiblePopoverFragment
      }
    }
  }
`;

export const query = graphql`
  query ($id: String!) {
    author: contentfulAuthor(id: { eq: $id }) {
      ...ContentfulAuthorPageFragment
    }
  }
`;
