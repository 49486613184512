import { CmsPage, ComposedPage } from "../components/ComposedPage";
import { graphql } from "gatsby";
import React, { FC } from "react";

export interface LandingPageProps {
  data: {
    page: CmsPage;
  };
}

export const LandingPageTemplate: FC<LandingPageProps> = ({
  data: { page }
}) => (
  <ComposedPage
    title={page.metaTitle || page.title}
    metaTitle={page.metaTitle}
    metaDescription={page.metaDescription}
    noIndex={page.noIndex}
    sections={page.sections}
    slug={page.slug}
    aboveFoldExperimentation={page.aboveFoldExperimentation}
    jsonSchema={page.jsonSchema}
  />
);

export default LandingPageTemplate;

export const pageQuery = graphql`
  query LandingPageQuery($id: String!) {
    page: contentfulLandingPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      jsonSchema {
        jsonSchema
      }
      aboveFoldExperimentation
      noIndex
      transparentHeader
      sections {
        __typename
        ... on Node {
          ...ContentfulCardPanelFragment
          ...ContentfulPageHeroFragment
          ...ContentfulLatestPostsCarouselFragment
          ...ContentfulPageCarouselFragment
          ...ContentfulTrustPilotSectionFragment
          ...ContentfulFourCardPanelFragment
          ...ContentfulDismissibleBannerFragment
          ...ContentfulPaddedPageBannerFragment
          ...ContentfulCenteredImagePanelFragment
          ...ContentfulDisclaimerSectionFragment
          ...ContentfulEmbeddedVideoSectionFragment
          ...ContentfulInfoAccordionFragment
          ...ContentfulComparisonTableSectionFragment
          ...ContentfulQuoteCardFragment
          ...ContentfulBlogPostRichTextSectionFragment
          ...ContentfulCalculatorSectionFragment
          ...ContentfulTickerTapeSectionFragment
          ...ContentfulCategoryFragment
          ...ContentfulDismissiblePopoverFragment
        }
      }
    }
  }
`;
