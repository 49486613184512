import styles from "./styles.module.scss";
import { useDisableScroll, useStyleOverride } from "@mettle/hooks";
import downArrow from "assets/icons/dropdown.svg";
import downArrowWhite from "assets/icons/dropdown_white.svg";
import cx from "classnames";
import { Link } from "components/Link";
import { handleNavigation, urlIsExternal } from "components/Link/hooks";
import {
  MenuLink,
  MenuSubLink,
  useMenuLinks
} from "components/Navigation/hooks/useMenuLinks";
import { useExternalNavigation } from "hooks";
import React, { useState } from "react";
import { VelocityTransitionGroup } from "velocity-react";

export const AnimatedMenuLinks: React.FC<{
  className?: string;
  openClassName?: string;
  isMenuClosing: boolean;
  hideMenu(): void;
}> = ({ className, openClassName, isMenuClosing, hideMenu }) => {
  useDisableScroll();
  useStyleOverride(document.body, {
    // @ts-ignore
    "overscroll-behavior": "none"
  });

  return (
    <ul className={cx(className, styles.links)}>
      {useMenuLinks().map(
        ({ id, title, subtitle, children, nestedSections }, index, links) => {
          if (nestedSections) {
            nestedSections.unshift({
              id,
              title,
              headerLinks: children,
              subtitle: subtitle,
              children: children
            });
          }

          return (
            <MobileLink
              id={id}
              key={id}
              title={title}
              children={children}
              nestedSections={nestedSections}
              delayIn={100 + index * 50}
              delayOut={(links.length + 1) * 100 - index * 50}
              isMenuClosing={isMenuClosing}
              hideMenu={hideMenu}
              openClassName={openClassName}
              primary
            />
          );
        }
      )}
    </ul>
  );
};
AnimatedMenuLinks.displayName = "AnimatedMenuLinks";

export interface MobileLinkProps extends MenuLink {
  delayIn: number;
  delayOut: number;
  isMenuClosing: boolean;
  openClassName?: string;
  secondary?: boolean;
  primary?: boolean;

  hideMenu(): void;
}

export const MobileLink: React.FC<MobileLinkProps> = ({
  id,
  title,
  children,
  delayIn,
  delayOut,
  isMenuClosing,
  openClassName,
  hideMenu,
  nestedSections = [],
  secondary,
  primary
}) => {
  const logExternalNavigation = useExternalNavigation();

  const navigate = React.useCallback(
    (url: string) => {
      hideMenu();
      logExternalNavigation(url, urlIsExternal(url));
      handleNavigation(url)({} as React.SyntheticEvent<any>);
    },
    [hideMenu]
  );

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li key={id}>
      <VelocityTransitionGroup
        runOnMount
        enter={{
          animation: "fadeIn",
          delay: delayIn,
          duration: 300
        }}
        leave={{
          animation: "fadeOut",
          delay: delayOut,
          duration: 300
        }}
      >
        {!isMenuClosing && (
          <>
            <Link
              className={cx({
                [styles.open]: isExpanded,
                [styles.secondary]: secondary,
                [openClassName!]: openClassName && isExpanded
              })}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span className={cx({ [styles.initialTitle]: primary })}>
                {title}
              </span>
              {children && <DropdownIcon white expanded={isExpanded} />}
            </Link>
            {children && (
              <VelocityTransitionGroup
                data-testid="sub-links"
                runOnMount
                enter={{
                  animation: "slideDown",
                  duration: 175
                }}
                leave={{
                  animation: "slideUp",
                  duration: 175
                }}
              >
                {isExpanded && (
                  <>
                    {nestedSections.length === 0 ? (
                      <MobileSubLinks links={children} navigate={navigate} />
                    ) : (
                      <ul className={styles.nestedSection}>
                        {nestedSections.map(
                          ({ id, subtitle, children }, index) => (
                            <MobileLink
                              id={id}
                              key={id}
                              title={subtitle!}
                              children={children!}
                              delayIn={100 + index * 50}
                              delayOut={
                                (children!.length + 1) * 100 - index * 50
                              }
                              isMenuClosing={isMenuClosing}
                              hideMenu={hideMenu}
                              openClassName={openClassName}
                              secondary
                            />
                          )
                        )}
                      </ul>
                    )}
                  </>
                )}
              </VelocityTransitionGroup>
            )}
          </>
        )}
      </VelocityTransitionGroup>
    </li>
  );
};
MobileLink.displayName = "MobileLink";

export const MobileSubLinks: React.FC<{
  links: MenuSubLink[];
  navigate(url: string): void;
}> = ({ links, navigate }) => (
  <ul data-testid="mobile-sub-links" className={styles.mobileSubLinks}>
    {links.map(({ id, title, url, isNew }) => (
      <li key={id} data-testid={url}>
        <Link url={url} onClick={() => navigate(url)}>
          {title}
          {isNew && <NewPill />}
        </Link>
      </li>
    ))}
  </ul>
);
MobileSubLinks.displayName = "MobileSubLinks";

const NewPill = () => <span className={styles.newPill}>New</span>;

export const MenuLinks: React.FC<{
  className?: string;
}> = ({ className }) => {
  const [hoveredMenuItem, setHoveredMenuItem] = useState<string | null>(null);

  return (
    <>
      <ul className={cx(styles.links, className)}>
        {useMenuLinks().map(
          ({ id, title, subtitle, children, nestedSections }) => (
            <li
              key={id}
              className={cx(styles.initial, {
                [styles.hovered]: hoveredMenuItem === id
              })}
              onMouseEnter={e => {
                setHoveredMenuItem(id);

                if (e.currentTarget.parentNode) {
                  const siblings = Array.from(
                    e.currentTarget.parentNode.children
                  ).filter(item => item !== e.currentTarget);

                  siblings.forEach((item: HTMLLIElement) => {
                    const child = item.firstElementChild as HTMLAnchorElement;

                    child.blur();
                  });
                }
              }}
              onMouseLeave={() => setHoveredMenuItem(null)}
            >
              <>
                <Link
                  allowPartialActive
                  activeClassName={styles.activeLink}
                  onClick={children && (() => {})}
                >
                  {title}
                  {children && <DropdownIcon white />}
                </Link>
                {children && (
                  <div
                    className={cx(styles.sublinks, {
                      [styles.fullWidth]: (nestedSections?.length || 0) > 1,
                      [styles.col2]: nestedSections?.length === 1,
                      [styles.col3]: nestedSections?.length === 2,
                      [styles.col4]: nestedSections?.length === 3
                    })}
                    data-testid="sub-links"
                  >
                    <ul>
                      {nestedSections?.length && (
                        <li className={styles.subtitle}>{subtitle}</li>
                      )}
                      {children.map(
                        ({ id, url, title, isNew }: MenuSubLink) => (
                          <li key={id} data-testid={url}>
                            <Link
                              allowPartialActive={url?.split("/").length > 3}
                              activeClassName={styles.activeLink}
                              url={url}
                            >
                              {title}
                              {isNew && <NewPill />}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>

                    {nestedSections?.map(section => {
                      return (
                        <ul key={section.id}>
                          <li className={styles.subtitle}>
                            {section.subtitle}
                          </li>
                          {section.children?.map(
                            ({ id, url, title, isNew }: MenuSubLink) => (
                              <li key={id} data-testid={url}>
                                <Link
                                  allowPartialActive={
                                    url?.split("/").length > 3
                                  }
                                  activeClassName={styles.activeLink}
                                  url={url}
                                >
                                  {title}
                                  {isNew && <NewPill />}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      );
                    })}
                  </div>
                )}
              </>
            </li>
          )
        )}
      </ul>
    </>
  );
};
MenuLinks.displayName = "MenuLinks";

export const DropdownIcon: React.FC<{
  expanded?: boolean;
  white?: boolean;
}> = ({ expanded, white }) => (
  <div
    data-testid="dropdown-icon"
    className={cx(styles.dropdownIcon, {
      [styles.open]: expanded
    })}
  >
    <img alt="Expand menu" src={white ? downArrowWhite : downArrow} />
  </div>
);
