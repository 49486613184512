import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Link } from "../Link";

export interface FooterLinkSectionProps {
  id: string;
  title: string;
  isPrivacy: Boolean;
  footerLinks: FooterLink[];
}

export type FooterLink = {
  id: string;
  url: string;
  name: string;
};
export const LinkSection: FC<FooterLinkSectionProps> = (
  section: FooterLinkSectionProps
) => {
  const preventDefaultHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (window.OneTrust) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  return (
    <div key={section.id} className={styles.linkSection}>
      <div className={styles.title}>{section.title}</div>
      <ul id={section.id}>
        {section.footerLinks.map((link: FooterLink) => {
          return (
            <li key={link.id}>
              <Link url={link.url} title={link.name}>
                {link.name}
              </Link>
            </li>
          );
        })}
        {section.isPrivacy && (
          <li>
            <button
              onClick={preventDefaultHandler}
              id="ot-sdk-btn"
              title="Cookie Preferences"
              tabIndex={0}
              className={`${styles.oneTrustLink} optanon-show-settings`}
            >
              Cookie Preferences
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};
