import React, { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CmsPageSectionBase, ImageFit } from "../CardPanel";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { useBlogRichText } from "../Blog/hooks";
import { HeroCtas } from "../PageHero/HeroCtas";
import cx from "classnames";
import { Colour } from "../constants";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Modal } from "../Modal";
import { CmsResponsiveImage } from "../Blog";

export interface CmsDismissiblePopover extends CmsPageSectionBase {
  title?: string;
  subtitle?: string;
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  showOpenAccountButton: boolean;
  primaryCtaText?: string;
  primaryCtaUrl?: string;
  secondaryCtaText?: string;
  secondaryCtaUrl?: string;
  delayInSeconds: string;
  image?: CmsResponsiveImage;
  richTextListMarkerStyle?: string;
}

export const DismissiblePopover: FC<CmsDismissiblePopover> = ({
  title,
  subtitle,
  richText = { raw: "", references: [] },
  richTextListMarkerStyle = "",
  delayInSeconds,
  contentful_id = "",
  showOpenAccountButton,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
  image
}) => {
  const [show, setShow] = useState(false);

  const handleDisplay = () => {
    setShow(true);
  };

  const handleOnClose = () => {
    window.localStorage.setItem(contentful_id, "viewed");
    setShow(false);
  };

  useEffect(() => {
    const isDismissed = window.localStorage.getItem(contentful_id);
    let timeout: ReturnType<typeof setTimeout>;

    if (!isDismissed) {
      timeout = setTimeout(handleDisplay, parseInt(delayInSeconds, 10) * 1000);
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [delayInSeconds, contentful_id]);

  const richTextRender = useBlogRichText(richText);

  return (
    show && (
      <Modal
        data-testid="popover"
        onClose={handleOnClose}
        className={styles.dismissibleModal}
        id={`dismissible-${title}`}
      >
        <div className={cx(styles.dismissiblePopover)}>
          <div className={cx(styles.content, styles[Colour.warmStone])}>
            <div className={cx(styles.text, styles[richTextListMarkerStyle!])}>
              {title && <h2 className={styles.title}>{title}</h2>}
              {subtitle && <h5>{subtitle}</h5>}
              {richText && richTextRender}
              <HeroCtas
                showOpenAccountButton={showOpenAccountButton}
                primaryCtaText={primaryCtaText}
                primaryCtaUrl={primaryCtaUrl}
                secondaryCtaText={secondaryCtaText}
                secondaryCtaUrl={secondaryCtaUrl}
              />
            </div>
            <div className={styles.image}>
              {image && (
                <GatsbyImage
                  alt={image.title}
                  imgStyle={{
                    objectFit: ImageFit.CONTAIN
                  }}
                  image={getImage(image.gatsbyImageData) as IGatsbyImageData}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

DismissiblePopover.displayName = "DismissiblePopover";

export const ContentfulDismissiblePopoverFragment = graphql`
  fragment ContentfulDismissiblePopoverFragment on ContentfulDismissiblePopover {
    __typename
    contentful_id
    title
    subtitle
    richText {
      raw
    }
    showOpenAccountButton
    primaryCtaText
    primaryCtaUrl
    secondaryCtaText
    secondaryCtaUrl
    delayInSeconds
    image {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
  }
`;
